export const generateQueryString = (parameters) => {
  const array = [];

  Object.keys(parameters).forEach((key) => {
    if (parameters[key]) {
      array.push(`${key}=${parameters[key]}`);
    }
  });

  return array.join("&");
};
