import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { generateQueryString } from "./utils";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

export function App() {
  let query = useQuery();

  useEffect(() => {
    let parameters = {
      cvt: query.get("cvt") || null,
      view: "hotels",
      destination: query.get("destination") || null,
      lat: query.get("lat") || null,
      lon: query.get("lon") || null,
      check_in: query.get("check_in"),
      check_out: query.get("check_out") || null,
      rooms: query.get("rooms") || null,
      language: query.get("language") || null,
      adults: query.get("adults") | null,
    };

    const script = document.createElement("script");

    const queryString = generateQueryString(parameters);

    const scriptSrc = `https://booking.accessdevelopment.com/scripts/integration.js${
      queryString !== "" ? `?${queryString}` : ""
    }`;

    script.src = scriptSrc;
    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, [query]);
  return null;
}
